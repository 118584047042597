import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllCandidate = createAsyncThunk(
    'Candidate/fetchAllCandidate',
    async (_,{ rejectWithValue }) => {
        try {
            const response = await request('get', URLS.CANDIDATE)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllByTanggalFilter = createAsyncThunk(
    'Candidate/fetchAllByTanggalFilter',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.CANDIDATE}/tanggal-range?startDate=${params.startDate}&endDate=${params.endDate}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchByScheduleId = createAsyncThunk(
    'Candidate/fetchByScheduleId',
    async (schedule_id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.CANDIDATE}?schedule_id=${schedule_id}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addCandidate = createAsyncThunk(
    'Candidate/addCandidate',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.CANDIDATE, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateCandidate = createAsyncThunk(
    'Candidate/updateCandidate',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('put', URLS.CANDIDATE, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneCandidate = createAsyncThunk(
    'Candidate/fetchOneCandidate',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.CANDIDATE}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteCandidate = createAsyncThunk(
    'Candidate/deleteCandidate',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.CANDIDATE}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const CandidateSlice = createSlice({
    name: 'Candidate',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllCandidate.pending, startLoadingQuery)
            .addCase(fetchAllCandidate.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllCandidate.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllByTanggalFilter.pending, startLoadingQuery)
            .addCase(fetchAllByTanggalFilter.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllByTanggalFilter.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneCandidate.pending, startLoadingQuery)
            .addCase(fetchOneCandidate.rejected, stopLoadingQuery)
            .addCase(fetchOneCandidate.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateCandidate.pending, startLoadingQuery)
            .addCase(updateCandidate.rejected, stopLoadingQuery)
            .addCase(updateCandidate.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchByScheduleId.pending, startLoadingQuery)
            .addCase(fetchByScheduleId.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchByScheduleId.rejected, stopLoadingQuery)
        builder
            .addCase(deleteCandidate.pending, startLoadingMutation)
            .addCase(deleteCandidate.fulfilled, stopLoadingMutation)
            .addCase(deleteCandidate.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = CandidateSlice.actions;

export default CandidateSlice.reducer;