import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'


export const dashboard = createAsyncThunk(
    'Surat/fetchDashboard',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SURAT + "/dashboard")
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllSurat = createAsyncThunk(
    'Surat/fetchAllSurat',
    async (params, { rejectWithValue }) => {
        try {
            let data = {};

            if (params.jenisSurat && params.jenisSurat !== "SEMUA") {
                data.jenisSurat = params.jenisSurat;
            }

            if (params.perihal) {
                data.perihal = params.perihal;
            }

            if (params.endDate && params.startDate) {
                data.endDate = params.endDate;
                data.startDate = params.startDate;
            }

            console.log(params)

            const response = await request('get', URLS.SURAT, data)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllByTanggalFilter = createAsyncThunk(
    'Surat/fetchAllByTanggalFilter',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SURAT}/tanggal-range?startDate=${params.startDate}&endDate=${params.endDate}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchSuratByJenis = createAsyncThunk(
    'Surat/fetchSuratByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SURAT}/jenis/${jenis}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addSurat = createAsyncThunk(
    'Surat/addSurat',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.SURAT, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateSurat = createAsyncThunk(
    'Surat/updateSurat',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('put', URLS.SURAT, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneSurat = createAsyncThunk(
    'Surat/fetchOneSurat',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SURAT}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteSurat = createAsyncThunk(
    'Surat/deleteSurat',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.SURAT}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const SuratSlice = createSlice({
    name: 'Surat',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(dashboard.pending, startLoadingQuery)
            .addCase(dashboard.fulfilled, (state, action) => {
                state.count = action.payload
                state.loading.query = false
            })
            .addCase(dashboard.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllSurat.pending, startLoadingQuery)
            .addCase(fetchAllSurat.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllSurat.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllByTanggalFilter.pending, startLoadingQuery)
            .addCase(fetchAllByTanggalFilter.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllByTanggalFilter.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneSurat.pending, startLoadingQuery)
            .addCase(fetchOneSurat.rejected, stopLoadingQuery)
            .addCase(fetchOneSurat.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateSurat.pending, startLoadingQuery)
            .addCase(updateSurat.rejected, stopLoadingQuery)
            .addCase(updateSurat.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchSuratByJenis.pending, startLoadingQuery)
            .addCase(fetchSuratByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchSuratByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteSurat.pending, startLoadingMutation)
            .addCase(deleteSurat.fulfilled, stopLoadingMutation)
            .addCase(deleteSurat.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = SuratSlice.actions;

export default SuratSlice.reducer;