import React, { useEffect, useState, useCallback, message } from "react";
import { Col, Row, Table, Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchAllCandidate } from "redux/features/candidates";
import { strings } from "res";
import { useHistory } from "react-router-dom";

export const CANDIDATE = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const history = useHistory();

  const getDataAll = async () => {
    try {
      const response = await dispatch(fetchAllCandidate()).unwrap();
      setLoading(false);
      setList(response);
    } catch (error) {
      console.log(error);
      // message.error(error?.message || "Failed to fetch data");
    }
  };

  const goToDetailSaksi = () => {
    history.push(strings.navigation.path.detail_saksi);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record) => {
        return <div>{record?.id || "-"}</div>;
      },
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return <div>{record?.name || "-"}</div>;
      },
    },
    {
      title: "Jenis",
      dataIndex: "election_type",
      key: "election_type",
      render: (_, record) => {
        return <div>{record?.election_type || "-"}</div>;
      },
    },
    {
      title: "Dapil",
      dataIndex: "electoral_area",
      key: "electoral_area",
      render: (_, record) => {
        return <div>{record?.electoral_area || "-"}</div>;
      },
    },
    {
      title: "Total Suara",
      dataIndex: "electoral_sum",
      key: "electoral_sum",
      render: (_, record) => {
        return <div>{record?.electoral_sum}</div>;
      },
    }
  ];

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Daftar Calon Legislatif & Presiden</h2>
          <p>Seluruh daftar caleg dan presiden</p>
        </Col>
      </Row>
      <Table dataSource={list} columns={columns} />
      {/* <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button type="primary" block onClick={goToDetailSaksi}>
            Tambah 
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

export default CANDIDATE;
