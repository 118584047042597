import React, { useEffect, useState, useCallback, message } from "react";
import { Col, Row, Table, Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { fetchByScheduleId } from "redux/features/attendance";
import { useHistory, useLocation } from 'react-router-dom';

// Function to format Unix timestamp to HH:MM:SS
function formatTime(unixTimestamp) {
    const dateObject = new Date(unixTimestamp); // Convert to milliseconds
    const hours = dateObject.getUTCHours().toString().padStart(2, '0');
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getUTCSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

export const ATTENDANCE = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const location = useLocation()

    const getDataAll = async (schedule_id) => {
        try {
            const response = await dispatch(fetchByScheduleId(schedule_id)).unwrap();
            setLoading(false);
            setList(response);
        } catch (error) {
            console.log(error);
            message.error(error?.message || "Failed to fetch data");
        }
    };

    useEffect(() => {
        const itemId = location.state?.schedule_id
        if(itemId){
            getDataAll(itemId)
            setLoading(false)
        }
    }, []);

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (_, record) => {
                return record.id
            },
        },
        {
            title: "Schedule",
            dataIndex: "schedule_id",
            key: "schedule_id",
            render: (_, record) => {
                return record?.schedule_id;
            },
        },
        {
            title: "Nomor TPS",
            dataIndex: "tps",
            key: "tps",
            render: (_, record) => {
                return record?.tps_id;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_, record) => {
                return record.user?.name;
            },
        },
        {
            title: "Email",
            dataIndex: "user_id",
            key: "user_id",
            render: (_, record) => {
                return record.user?.email;
            },
        },
        {
            title: "Status",
            dataIndex: "status_location",
            key: "status_location",
            render: (_, record) => {
                if(record.status_location === "not on location"){
                    return <div style={{color:"red"}}>{record.status_location}</div>
                }else{
                    return <div style={{color:"green"}}>{record.status_location}</div>
                }
            },
        },
        {
            title: "Waktu Absen",
            dataIndex: "time_of_attendance",
            key: "time_of_attendance",
            render: (_, record) => {
                return moment(record.time_of_attendance).tz("Asia/Jakarta").format("LTS");
            },
        },
        {
            title: "Bukti",
            dataIndex: "proof",
            key: "proof",
            render: (_, record) => {
                return <a href={`https://gerindra-saksi-api.gawegawe.id/uploads/`+record.proof} target="_blank">Lihat</a>;
            },
        },
        {
            title: "Jarak Dari TPS",
            dataIndex: "jarak",
            key: "jarak",
            render: (_, record) => {
                return record?.distance || "Belum Ditentukan";
            },
        }
    ];

    return (
        <div>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Semua Attendance</h2>
                    <p>Seluruh daftar attendance</p>
                </Col>
            </Row>
            <Table loading={isLoading} dataSource={list} columns={columns} />
        </div>
    );
};

export default ATTENDANCE;
