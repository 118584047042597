import { strings } from "res";
import {
  CommandCenterIcon,
  DashboardIcon,
  DeliveryIcon,
  OrderIcon,
  OutletIcon,
  ReportIcon,
  SLAIcon,
  CustomerIcon,
  SettingIcon,
} from "../assets/svg/icon";

const dashBoardNavTree = [
  {
    key: "Home",
    path: strings.navigation.path.dashboard,
    title: "Home",
    breadcrumb: false,
    submenu: [
      {
        key: "Dashboard",
        path: strings.navigation.path.dashboard,
        title: "Dashboard",
        icon: DashboardIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Schedules",
        path: strings.navigation.path.schedule,
        title: "Jadwal Absen",
        icon: ReportIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Daftar Koor & Saksi",
        path: strings.navigation.path.daftar_saksi,
        title: "Daftar Koor &  Saksi",
        icon: CommandCenterIcon,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "Quick Count",
      //   path: strings.navigation.path.quick_count,
      //   title: "Quick Count",
      //   icon: OrderIcon,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "Daftar Calon",
        path: strings.navigation.path.daftar_calon,
        title: "Daftar Calon",
        icon: SLAIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Daftar TPS",
        path: strings.navigation.path.daftar_tps,
        title: "Daftar TPS",
        icon: OutletIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Settings",
        path: strings.navigation.path.settings,
        title: "Settings",
        icon: SettingIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Logout",
        path: strings.navigation.path.logout,
        title: "Logout",
        icon: CustomerIcon,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  // ...ControlTree,
];

export default navigationConfig;
