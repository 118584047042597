import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "redux/features/theme";
import authReducer from "redux/features/auth";
import suratReducer from "redux/features/surat";
import tandaReducer from "redux/features/tanda";
import penggunaReducer from "redux/features/pengguna";
import candidateReducer from "redux/features/candidates";
import scheduleReducer from "redux/features/schedule";
import tpsReducer from "redux/features/tps";
import saksiReducer from "redux/features/saksi";

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    surat: suratReducer,
    tanda: tandaReducer,
    schedule: scheduleReducer,
    pengguna: penggunaReducer,
    candidates: candidateReducer,
    tps: tpsReducer,
    saksi: saksiReducer,
  },
});

export default store;
