import React, { useEffect, useState, useCallback, message } from "react";
import { Col, Row, Table, Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { fetchAllSchedule } from "redux/features/schedule";
import { useHistory } from "react-router-dom";
import { strings } from "res";

 // Function to format Unix timestamp to HH:MM:SS
 function formatTime(unixTimestamp) {
  const dateObject = new Date(unixTimestamp); // Convert to milliseconds
  const hours = dateObject.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getUTCSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

export const SCHEDULE = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const history = useHistory();

  const getDataAll = async () => {
    try {
      const response = await dispatch(fetchAllSchedule()).unwrap();
      setLoading(false);
      setList(response);
    } catch (error) {
      console.log(error);
      // message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const goToDetailSchedule = () => {
    history.push(strings.navigation.path.detail_schedule);
  };

  const gotToDetailAttendance = (schedule_id) => {
  
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return record.id
      },
    },
    {
      title: "Tanggal",
      dataIndex: "scheduleDateTime",
      key: "scheduleDateTime",
      render: (_, record) => {
        return moment(record.scheduleDateTime).format("DD-MM-YYYY");
      },
    },
    {
      title: "Sesi Mulai",
      dataIndex: "latitude",
      key: "latitude",
      render: (_, record) => {
        return moment(record.scheduleDateTime).tz("Asia/Jakarta").format('LTS');
      },
    },
    {
      title: "Sesi Selesai",
      dataIndex: "latitude",
      key: "latitude",
      render: (_, record) => {
        return moment(record.expiredDateTime).tz("Asia/Jakarta").format('LTS');
      },
    },
    // {
    //   title: "Sesi Mulai",
    //   dataIndex: "sesi_mulai",
    //   key: "sesi_mulai",
    //   render: (_, record) => {
    //     return record.scheduleUnixTime);
    //   },
    // },
    // {
    //   title: "Sesi Selesai",
    //   dataIndex: "latitude",
    //   key: "latitude",
    //   render: (_, record) => {
    //     return moment(record.expiredDateTime).unix();
    //   },
    // },
    // {
    //   title: "Expired",
    //   dataIndex: "expired",
    //   key: "expired",
    //   render: (_, record) => {
    //     return moment(record.expiredDateTime).unix();
    //   },
    // },
    // {
    //   title: "Sekarang",
    //   dataIndex: "sekarang",
    //   key: "sekarang",
    //   render: (_, record) => {
    //     return moment().unix();
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if(moment(record.scheduleDateTime).unix() > moment().unix()){
          return <div style={{color:"red"}}>Belum Mulai</div>
        }else{
          if(moment(record.expiredDateTime).unix() > moment().unix()){
            return <div style={{color:"orange"}}>Sedang Berjalan</div>
          }else{
            return <div style={{color:"green"}}>Selesai</div>
          }
        }
      },
    },
    {
      title: "Total Absen",
      dataIndex: "absen",
      key: "absen",
      render: (_, record) => {
        return record.attendance.length;
      },
    },
    {
      title: "Semua Absen",
      dataIndex: "schedule_id",
      key: "schedule_id",
      render: (_, record) => {
        console.log(record)
        return (
          <div onClick={()=>{
            history.push({
              pathname:strings.navigation.path.detail_attendance,
              state:{
                schedule_id:record.id
              }
            });
          }}>
            <a>Lihat Semua</a>
          </div>
        );
      },
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      render: (_, record) => {
        return (
          <div onClick={goToDetailSchedule}>
            <a>Detail</a>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Schedules</h2>
          <p>Seluruh daftar schedule</p>
        </Col>
      </Row>
      <Table dataSource={list} columns={columns} />
      <br/>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button type="primary" block onClick={goToDetailSchedule}>
            Tambah Sesi Absen
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SCHEDULE;
