import React, { useEffect, useState, useCallback, message } from "react";
import { Col, Row, Table, Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchAllUser } from "redux/features/user";
import { strings } from "res";
import { useHistory } from "react-router-dom";

export const SAKSI = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const history = useHistory();

  const getDataAll = async () => {
    try {
      const response = await dispatch(fetchAllUser()).unwrap();
      console.log(response)
      setLoading(false);
      setList(response);
    } catch (error) {
      console.log(error);
      // message.error(error?.message || "Failed to fetch data");
    }
  };

  const goToDetailSaksi = () => {
    history.push(strings.navigation.path.detail_saksi);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record) => {
        return <div>{record?.id || "-"}</div>;
      },
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return <div>{record?.name || "-"}</div>;
      },
    },
    {
      title: "TPS",
      dataIndex: "tps",
      key: "tps",
      render: (_, record) => {
        return <div>{record?.role_users[0]?.role?.name === 'WITNESS' ? record?.tps_number : "-"}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        return <div>{record?.email || "-"}</div>;
      },
    },
    // {
    //   title: "No HP",
    //   dataIndex: "no_hp",
    //   key: "no_hp",
    //   render: (_, record) => {
    //     return <div>{record?.no_hp || "-"}</div>;
    //   },
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_, record) => {
        return <div>{record?.role_users[0]?.role?.name || "-"}</div>;
      },
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      render: (_, record) => {
        return (
          <div>
            <a>Detail</a>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Daftar Koordinator & Saksi</h2>
          <p>Seluruh daftar saksi</p>
        </Col>
      </Row>
      <Table dataSource={list} columns={columns} />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button type="primary" block onClick={goToDetailSaksi}>
            Tambah Koor atau Saksi
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SAKSI;
