import { Col, Row, Card, Select, Table } from "antd";
import StatisticWidget from "components/shared-components/StatisticWidget";
import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { strings } from 'res'
import { withRouter } from "react-router-dom";
import { fetchAllByTanggalFilter } from "redux/features/schedule";
import Axios from "axios";
import ReactMapGL, { FullscreenControl, Marker, Popup } from 'react-map-gl'
import moment from "moment-timezone";

const Option = Select


const columns = [
  {
    title: "Kecamatan",
    dataIndex: "village",
    key: "village",
  },
  {
    title: "Kelurahan",
    dataIndex: "sub_district",
    key: "sub_district",
  },
  {
    title: "Nama",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Nomor TPS",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    key: "longitude",
  },
  {
    title: "Status Saksi",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      if(record.status === "NOT_YET"){
        return <div style={{color:"red"}}>Belum Ada Saksi</div>
      }else{
        return <div style={{color:"green"}}>Sudah Ada Saksi</div>
      }
    },
  },
];

export const AnnualStatisticData = [
  {
    title: "Total Surat",
    value: "Loading...",
  },
  {
    title: "Surat Masuk",
    value: "Loading...",
  },
  {
    title: "Surat Keluar",
    value: "Loading...",
  },
];

export const DefaultDashboard = () => {
  const dispatch = useDispatch()
  const [time, setTime] = useState(null)
  // const [totalTPS, setTotalTPS] = useState(0)
  const [opsi, setOpsi] = useState('sudah-absen')
  const [date, setDate] = useState(null)
  const [tps, setTPS] = useState([])
  const [saksi, setSaksi] = useState(null)
  const [schedules, setSchedules] = useState([])
  const [viewport, setViewport] = useState({
    width: '100%',
    height: '500px',
    latitude: -2.990934,
    longitude: 104.756554,
    zoom: 11
  });

  const getAllSchedule = async () => {
    try {
      const response = await dispatch(fetchAllByTanggalFilter(moment().tz("Asia/Jakarta").format("YYYY-MM-DD"))).unwrap();
      // setLoading(false);
      // console.log(moment().tz("Asia/Jakarta").format("YYYY-MM-DD"))
      setSchedules(response);
    } catch (error) {
      console.log(error);
      // message.error(error?.message || "Failed to fetch data");
    }
  };

  const getDashboardData = async () => {
    let token = localStorage.getItem('token')

    await Axios.get(`${strings.api.host}/dashboard?currentTimeStamp=${1707867000000}`, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': "*"
      }
    }).then(doc => {
      console.log(doc.data.data.tps)
      setTPS(doc.data.data.tps)
      setSaksi(doc.data.data.total_witness)
      // setTotalAttendance(doc.data.data.total_attendance)
      // setSchedules(doc.data.data.schedules)
    })
    getAllSchedule()
  }

  useEffect(() => {
    getDashboardData()
  }, [])

  useEffect(() => {
    setDate(moment().format('DD-MM-YYYY'))
    setInterval(() => {
      setTime(moment().format('LTS'))
    }, 1000)
  }, [])

  const renderStatus = (schedule) => {
    if (moment(schedule.scheduleDateTime).unix() > moment().unix()) {
      return <div style={{ color: "red" }}>Belum Mulai</div>
    } else {
      if (moment(schedule.expiredDateTime).unix() > moment().unix()) {
        return <>
          <div style={{ color: "yellow" }}>Sedang Berjalan</div>
          <h5 style={{ textAlign: 'center', color: "white" }} >{schedule.attendance.length}/{saksi}</h5>
        </>
      } else {
        return <>
          <div style={{ color: "green" }}>Selesai</div>
          <h5 style={{ textAlign: 'center', color: "red" }} >{schedule.attendance.length}/{saksi}</h5>
        </>
      }
    }
  }

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Silahkan klik TPS dan dapat melihat alamat dan daftar saksi yang sudah maupun belum absen dan di lokasi</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total TPS`}
                value={tps?.length || "Loading..."}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Saksi`}
                value={saksi || "Loading..."}
              />
            </Col>
            {/* <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Attendance Hari Ini`}
                value={totalAttendance}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <h3 style={{ textAlign: 'center' }}>Hari Ini: {date}</h3>
            <h3 style={{ textAlign: 'center' }}>Jam: {time}</h3>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          {schedules.map(schedule => {
            return (
              <Card style={{ background: moment(schedule.expiredDateTime).unix() > moment().unix() ? "green" : "white" }}>
                <h5 style={{ textAlign: 'center', color: moment(schedule.expiredDateTime).unix() > moment().unix() ? "white" : "red" }}>{`Start: ${moment(schedule.scheduleDateTime).tz("Asia/Jakarta").format('LTS')}`}</h5>
                <h5 style={{ textAlign: 'center', color: moment(schedule.expiredDateTime).unix() > moment().unix() ? "white" : "red" }}>{`Selesai: ${moment(schedule.expiredDateTime).tz("Asia/Jakarta").format('LTS')}`}</h5>
                <h5 style={{ textAlign: 'center', color: "grey" }}>{
                  renderStatus(schedule)
                }</h5>
              </Card>
            )
          })}
        </Col>
      </Row>
      {/* <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <h3 style={{ textAlign: 'center', color: "green" }}>Hanya Menampilkan Yang Sudah Absen</h3>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Select style={{ width: "100%", marginBottom: "10px" }} defaultValue={'sudah-absen'} onChange={(value) => {
              setOpsi(value)
            }}>
              <Option value={"sudah-absen"} >Tampilkan Sudah Absen</Option>
              <Option value={"semua"}>Tampilkan Semua</Option>
              {/* <Option value={"belum-absen"} >Tampilkan Belum Absen</Option> */}
            </Select>
            {tps.length === 4777 && (
              <ReactMapGL
                mapboxApiAccessToken={'pk.eyJ1Ijoic2FuZ3Nha2F3aXJhIiwiYSI6ImNqdXBhajZmeTBudXg0NG50YjdhcDF2amUifQ.NmC56k1T54xEKGmlrFOxRA'}
                {...viewport}
                width={"100%"}
                onViewportChange={nextViewport => setViewport(nextViewport)}
              >
                {/* <div style={{ position: 'absolute', right: 5, top: 5 }}>
                <FullscreenControl />
              </div> */}
                {tps.map(data => {
                  if (opsi === 'semua' || opsi === 'belum-absen') {
                    if (data?.attendance?.length === 0) {
                      return (
                        <Marker
                          latitude={parseFloat(data.latitude)}
                          longitude={parseFloat(data.longitude)}
                          offsetLeft={-15}
                          offsetTop={-30}
                        >
                          <h5 style={{ color: "red" }}>x</h5>
                        </Marker>
                      )

                    } else {
                      if (data?.attendance?.length > 0) {
                        return (
                          <Marker
                            latitude={parseFloat(data.latitude)}
                            longitude={parseFloat(data.longitude)}
                            offsetLeft={-15}
                            offsetTop={-30}
                          >
                            <h5 style={{ color: "green" }}>o</h5>
                          </Marker>
                        )
                      } else {
                        return (
                          <Marker
                            latitude={parseFloat(data.latitude)}
                            longitude={parseFloat(data.longitude)}
                            offsetLeft={-15}
                            offsetTop={-30}
                          >
                            <h5 style={{ color: "green" }}>o</h5>
                          </Marker>
                        )
                      }
                    }
                  }else{
                    if (data?.attendance?.length > 0) {
                      return (
                        <Marker
                          latitude={parseFloat(data.latitude)}
                          longitude={parseFloat(data.longitude)}
                          offsetLeft={-15}
                          offsetTop={-30}
                        >
                          <h5 style={{ color: "green" }}>o</h5>
                        </Marker>
                      )
                    }
                  }
                })}
              </ReactMapGL>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Daftar TPS Yang Masih Belum Ada Saksi</h2>
          <Table dataSource={tps} columns={columns} />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(DefaultDashboard);
