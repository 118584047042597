import React, { useEffect, useState, useCallback, message } from "react";
import { Col, Row, Table, Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTPS } from "redux/features/tps";
import { useHistory } from "react-router-dom";
import { strings } from "res";

export const DAFTAR_TPS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [list,setList] = useState([])

  const getDataAll = async () => {
    try {
      const response = await dispatch(fetchAllTPS()).unwrap();
      setLoading(false);
      console.log(response)
      setList(response.data)
    } catch (error) {
      console.log(error)
      // message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  
const columns = [
  {
    title: "Kecamatan",
    dataIndex: "village",
    key: "village",
  },
  {
    title: "Keluarahan",
    dataIndex: "sub_district",
    key: "sub_district",
  },
  {
    title: "Nama",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Nomor TPS",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    key: "longitude",
  }
];

  const goToDetailTPS = () => {
    history.push(strings.navigation.path.detail_tps);
  };

  return (
    <div>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Daftar TPS</h2>
          <p>Seluruh daftar TPS</p>
        </Col>
      </Row>
      <Table dataSource={list} columns={columns} />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button onClick={goToDetailTPS} type="primary" block>
            Tambah TPS
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DAFTAR_TPS;
