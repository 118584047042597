import React, { useState } from "react";
import { Button, Form, TimePicker, Space, Col, Row, DatePicker, message } from "antd";
import { useHistory } from "react-router-dom";
import { addSchedule } from "redux/features/schedule";
import { strings } from "res";
import moment from "moment";
import { useDispatch } from "react-redux";

export const DETAIL_SCHEDULE = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [dateTime,setDateTime] = useState(null)
  const [startTime,setStartTime] = useState(null)
  const [expiredTime,setExpiredTime] = useState(null)

  const layout = {};

  const validateMessages = {
    required: "${label} is required!",
  };

  const onFinish = async (values) => {
    // scheduleDateTime
    // MM-DD-YYYY
    // 01-11-2024 09:00:00
    // expiredDateTime
    // 01-11-2024 09:00:00
    const payload = {
      // date:dateTime,
      scheduleDateTime:`${moment(dateTime).format("MM-DD-YYYY")} ${startTime}`,
      expiredDateTime:`${moment(dateTime).format("MM-DD-YYYY")} ${expiredTime}`
    }
    try{
      const response = await dispatch(addSchedule(payload)).unwrap()
      history.push("/app/schedule");
    }catch(err){
      message.error("Opps something is wrong!")
    }
  };

  const onCancel = () => {
    console.log("cancel");
    history.push(strings.navigation.path.schedule);
  };

  const onChangeDate = (date, dateString) => {
    // console.log(date, dateString);
    setDateTime(dateString)
  };

  const onChangeStartTime = (time, timeString) => {
    // console.log(time, timeString);
    setStartTime(timeString)
  };

  const onChangeEndTime = (time, timeString) => {
    // console.log(time, timeString);
    setExpiredTime(timeString)
  };

  return (
    <Row gutter={24}>
      <h2>Tambah Schedule</h2>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form
          {...layout}
          // name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={"date"}
            label="Tanggal"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={onChangeDate} />
          </Form.Item>
          <Form.Item
            name={"startTime"}
            label="Start Time"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker
              style={{ width: "100%" }}
              onChange={onChangeStartTime}
              defaultValue={moment("00:00:00", "hh:mm:ss")}
            />
          </Form.Item>
          <Form.Item
            name={"endTime"}
            label="End Time"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker
              onChange={onChangeEndTime}
              style={{ width: "100%" }}
              defaultValue={moment("00:00:00", "hh:mm:ss")}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default DETAIL_SCHEDULE;
