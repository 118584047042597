import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import URLS from "redux/urls";
import request from "redux/utils/request";

export const dashboard = createAsyncThunk(
  "Saksi/fetchDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request("get", URLS.SAKSI + "/dashboard");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllSaksi = createAsyncThunk(
  "Saksi/fetchAllSaksi",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request("get", URLS.SAKSI);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSaksi = createAsyncThunk(
  "Saksi/addSaksi",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request("post", URLS.SAKSI, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSaksi = createAsyncThunk(
  "Saksi/updateSaksi",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request("put", URLS.SAKSI, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchOneSaksi = createAsyncThunk(
  "Saksi/fetchOneSaksi",
  async (id, { rejectWithValue }) => {
    try {
      const response = await request("get", `${URLS.SAKSI}/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSaksi = createAsyncThunk(
  "Saksi/deleteSaksi",
  async (id, { rejectWithValue }) => {
    try {
      const response = await request("delete", `${URLS.SAKSI}/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: {
    query: false,
    mutation: false,
  },
  filter: {
    q: "",
  },
  list: [],
  count: {},
  selected: {},
  selectedRows: [],
};

const loadingReducer = (fieldName, status) => (state) => {
  state.loading[fieldName] = status;
};

const startLoadingQuery = loadingReducer("query", true);
const stopLoadingQuery = loadingReducer("query", false);
const startLoadingMutation = loadingReducer("mutation", true);
const stopLoadingMutation = loadingReducer("mutation", false);

export const saksiSlice = createSlice({
  name: "Saksi",
  initialState,
  reducers: {
    setAppliedSearchText: (state, action) => {
      state.filter.q = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboard.pending, startLoadingQuery)
      .addCase(dashboard.fulfilled, (state, action) => {
        state.count = action.payload;
        state.loading.query = false;
      })
      .addCase(dashboard.rejected, stopLoadingQuery);
    builder
      .addCase(fetchAllSaksi.pending, startLoadingQuery)
      .addCase(fetchAllSaksi.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading.query = false;
      })
      .addCase(fetchAllSaksi.rejected, stopLoadingQuery);
    builder
      .addCase(fetchOneSaksi.pending, startLoadingQuery)
      .addCase(fetchOneSaksi.rejected, stopLoadingQuery)
      .addCase(fetchOneSaksi.fulfilled, (state, action) => {
        state.loading.query = false;
        state.selected = action.payload;
      });
    builder
      .addCase(updateSaksi.pending, startLoadingQuery)
      .addCase(updateSaksi.rejected, stopLoadingQuery)
      .addCase(updateSaksi.fulfilled, (state, action) => {
        state.loading.query = false;
        state.selected = action.payload;
      });
    builder
      .addCase(deleteSaksi.pending, startLoadingMutation)
      .addCase(deleteSaksi.fulfilled, stopLoadingMutation)
      .addCase(deleteSaksi.rejected, stopLoadingMutation);
  },
});

export const { setSelectedRows, setAppliedSearchText } = saksiSlice.actions;

export default saksiSlice.reducer;
