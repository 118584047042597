import React from "react";
import { Button, Form, Input, Space, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { strings } from "res";

export const DETAIL_TPS = () => {
  const history = useHistory();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const onFinish = (values) => {
    console.log(values);
  };

  const onCancel = () => {
    console.log("cancel");
    history.push(strings.navigation.path.daftar_tps);
  };

  return (
    <Row gutter={24}>
      <Col xs={24} sm={24} md={24} lg={24}>
        <h2>Tambah TPS</h2>
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={"nomor_tps"}
            label="Nomor TPS"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"kabupaten"}
            label="Kabupaten"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"kecamatan"}
            label="Kecamatan"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"longitude"}
            label="Longitude"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"latitude"}
            label="Latitude"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default DETAIL_TPS;
