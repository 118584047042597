import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'


export const dashboard = createAsyncThunk(
    'Schedule/fetchDashboard',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SCHEDULE + "/dashboard")
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllSchedule = createAsyncThunk(
    'Schedule/fetchAllSchedule',
    async (_,{ rejectWithValue }) => {
        try {
            const response = await request('get', URLS.SCHEDULE)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllByTanggalFilter = createAsyncThunk(
    'Schedule/fetchAllByTanggalFilter',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SCHEDULE}?scheduleDateTime=${params}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchScheduleByJenis = createAsyncThunk(
    'Schedule/fetchScheduleByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SCHEDULE}/jenis/${jenis}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addSchedule = createAsyncThunk(
    'Schedule/addSchedule',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.SCHEDULE, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateSchedule = createAsyncThunk(
    'Schedule/updateSchedule',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('put', URLS.SCHEDULE, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneSchedule = createAsyncThunk(
    'Schedule/fetchOneSchedule',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.SCHEDULE}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteSchedule = createAsyncThunk(
    'Schedule/deleteSchedule',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.SCHEDULE}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const ScheduleSlice = createSlice({
    name: 'Schedule',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(dashboard.pending, startLoadingQuery)
            .addCase(dashboard.fulfilled, (state, action) => {
                state.count = action.payload
                state.loading.query = false
            })
            .addCase(dashboard.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllSchedule.pending, startLoadingQuery)
            .addCase(fetchAllSchedule.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllSchedule.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllByTanggalFilter.pending, startLoadingQuery)
            .addCase(fetchAllByTanggalFilter.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllByTanggalFilter.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneSchedule.pending, startLoadingQuery)
            .addCase(fetchOneSchedule.rejected, stopLoadingQuery)
            .addCase(fetchOneSchedule.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateSchedule.pending, startLoadingQuery)
            .addCase(updateSchedule.rejected, stopLoadingQuery)
            .addCase(updateSchedule.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchScheduleByJenis.pending, startLoadingQuery)
            .addCase(fetchScheduleByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchScheduleByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteSchedule.pending, startLoadingMutation)
            .addCase(deleteSchedule.fulfilled, stopLoadingMutation)
            .addCase(deleteSchedule.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = ScheduleSlice.actions;

export default ScheduleSlice.reducer;