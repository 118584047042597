import React from "react";
import { Button, Form, Input, Space, Col, Row, InputNumber, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import { register } from "redux/features/auth";
import { strings } from "res";
import { useDispatch } from "react-redux";

const Option = Select

export const DETAIL_SAKSI = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  // const layout = {
  //   labelCol: {
  //     span: 8,
  //   },
  //   wrapperCol: {
  //     span: 16,
  //   },
  // };

  const validateMessages = {
    required: "${label} is required!",
  };

  const onFinish = async (values) => {
    try {
      console.log(values)
      // const response = await dispatch(register(values)).unwrap()

    } catch (err) {
      message.error("Opps something is wrong!")
    }
  };

  const onCancel = () => {
    console.log("cancel");
    history.push(strings.navigation.path.daftar_saksi);
  };

  return (
    <div>
      <Row gutter={24}>
        <h2>Tambah Saksi atau Koordinator</h2>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form
            // {...layout}
            // name="nest-messages"
            onFinish={onFinish}
            // style={{
            //   maxWidth: 600,
            // }}
            validateMessages={validateMessages}
          >
            <Form.Item
              name={"name"}
              label="Nama Akun"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"email"}
              label="Email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"no_hp"}
              label="No HP"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item
              name={"type"}
              label="Koordinator atau Saksi"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select >
                <Option value="koordinator">Koordinator</Option>
                <Option value="saksi">Saksi</Option>
              </Select>
            </Form.Item>

            <a>Khusus saksi wajib isi ID tps dan silahkan lihat disini</a>

            <Form.Item
              name={"tps"}
              label="ID TPS"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="button" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default DETAIL_SAKSI;
