import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import { strings } from "res";
import Loading from "components/shared-components/Loading";

// Pages
import DASHBOARD from "./dashboard";
import DETAIL_ATTENDANCE from "./detail-attendance";
import SCHEDULE from "./schedule";
import DETAIL_SCHEDULE from "./detail-schedule";
import DAFTAR_TPS from "./daftar-tps";
import DETAIL_TPS from "./detail-tps";
import DAFTAR_SAKSI from "./daftar-saksi";
import DETAIL_SAKSI from "./detail-saksi";
import DAFTAR_CALON from "./calon";

// Detail
import DETAIL_PENGGUNA from "./detail-pengguna";

import SETTINGS from "./settings";

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          path={`${strings.navigation.path.dashboard}`}
          component={DASHBOARD}
        />
        <PrivateRoute
          path={`${strings.navigation.path.schedule}`}
          component={SCHEDULE}
        />
        <PrivateRoute
          path={`${strings.navigation.path.daftar_calon}`}
          component={DAFTAR_CALON}
        />
        <PrivateRoute
          path={`${strings.navigation.path.daftar_saksi}`}
          component={DAFTAR_SAKSI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_saksi}`}
          component={DETAIL_SAKSI}
        />
        <PrivateRoute
          path={`${strings.navigation.path.daftar_tps}`}
          component={DAFTAR_TPS}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_tps}`}
          component={DETAIL_TPS}
        />
        <PrivateRoute
          path={`${strings.navigation.path.detail_attendance}`}
          component={DETAIL_ATTENDANCE}
        />

        <PrivateRoute
          path={`${strings.navigation.path.detail_schedule}`}
          component={DETAIL_SCHEDULE}
        />

        {/* DETAIL */}
        {/* <PrivateRoute
          path={`${strings.navigation.path.detail_pengguna}`}
          component={DETAIL_PENGGUNA}
        /> */}
        <PrivateRoute
          path={`${strings.navigation.path.settings}`}
          component={SETTINGS}
        />

        <Redirect
          from={`${match.url}`}
          to={`${strings.navigation.path.dashboard}`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
