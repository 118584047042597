import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'


export const dashboard = createAsyncThunk(
    'Tanda/fetchDashboard',
    async (_, { rejectWithValue }) => {
        try {
            const response = await request('get', URLS.TANDA + "/dashboard")
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllTanda = createAsyncThunk(
    'Tanda/fetchAllTanda',
    async (params, { rejectWithValue }) => {
        try {
            let data = {};

            if (params.jenisTanda && params.jenisTanda !== "SEMUA") {
                data.jenisTanda = params.jenisTanda;
            }

            if (params.perihal) {
                data.perihal = params.perihal;
            }

            if (params.endDate && params.startDate) {
                data.endDate = params.endDate;
                data.startDate = params.startDate;
            }

            const response = await request('get', URLS.TANDA, data)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchAllByTanggalFilter = createAsyncThunk(
    'Tanda/fetchAllByTanggalFilter',
    async (params, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.TANDA}/tanggal-range?startDate=${params.startDate}&endDate=${params.endDate}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchTandaByJenis = createAsyncThunk(
    'Tanda/fetchTandaByJenis',
    async (jenis, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.TANDA}/jenis/${jenis}`)
            console.log(response)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addTanda = createAsyncThunk(
    'Tanda/addTanda',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.TANDA, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateTanda = createAsyncThunk(
    'Tanda/updateTanda',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('put', URLS.TANDA, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneTanda = createAsyncThunk(
    'Tanda/fetchOneTanda',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.TANDA}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteTanda = createAsyncThunk(
    'Tanda/deleteTanda',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.TANDA}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const TandaSlice = createSlice({
    name: 'Tanda',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(dashboard.pending, startLoadingQuery)
            .addCase(dashboard.fulfilled, (state, action) => {
                state.count = action.payload
                state.loading.query = false
            })
            .addCase(dashboard.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllTanda.pending, startLoadingQuery)
            .addCase(fetchAllTanda.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllTanda.rejected, stopLoadingQuery)
        builder
            .addCase(fetchAllByTanggalFilter.pending, startLoadingQuery)
            .addCase(fetchAllByTanggalFilter.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllByTanggalFilter.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneTanda.pending, startLoadingQuery)
            .addCase(fetchOneTanda.rejected, stopLoadingQuery)
            .addCase(fetchOneTanda.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateTanda.pending, startLoadingQuery)
            .addCase(updateTanda.rejected, stopLoadingQuery)
            .addCase(updateTanda.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(fetchTandaByJenis.pending, startLoadingQuery)
            .addCase(fetchTandaByJenis.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchTandaByJenis.rejected, stopLoadingQuery)
        builder
            .addCase(deleteTanda.pending, startLoadingMutation)
            .addCase(deleteTanda.fulfilled, stopLoadingMutation)
            .addCase(deleteTanda.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = TandaSlice.actions;

export default TandaSlice.reducer;