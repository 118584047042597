import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import URLS from "redux/urls";
import request from "redux/utils/request";

export const dashboard = createAsyncThunk(
  "TPS/fetchDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request("get", URLS.TPS + "/dashboard");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllTPS = createAsyncThunk(
  "TPS/fetchAllTPS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request("get", URLS.TPS+"?limit=10000");
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllByTanggalFilter = createAsyncThunk(
  "TPS/fetchAllByTanggalFilter",
  async (params, { rejectWithValue }) => {
    try {
      const response = await request(
        "get",
        `${URLS.TPS}/tanggal-range?startDate=${params.startDate}&endDate=${params.endDate}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTPSByJenis = createAsyncThunk(
  "TPS/fetchTPSByJenis",
  async (jenis, { rejectWithValue }) => {
    try {
      const response = await request("get", `${URLS.TPS}/jenis/${jenis}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTPS = createAsyncThunk(
  "TPS/addTPS",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request("post", URLS.TPS, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTPS = createAsyncThunk(
  "TPS/updateTPS",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request("put", URLS.TPS, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchOneTPS = createAsyncThunk(
  "TPS/fetchOneTPS",
  async (id, { rejectWithValue }) => {
    try {
      const response = await request("get", `${URLS.TPS}/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTPS = createAsyncThunk(
  "TPS/deleteTPS",
  async (id, { rejectWithValue }) => {
    try {
      const response = await request("delete", `${URLS.TPS}/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: {
    query: false,
    mutation: false,
  },
  filter: {
    q: "",
  },
  list: [],
  count: {},
  selected: {},
  selectedRows: [],
};

const loadingReducer = (fieldName, status) => (state) => {
  state.loading[fieldName] = status;
};

const startLoadingQuery = loadingReducer("query", true);
const stopLoadingQuery = loadingReducer("query", false);
const startLoadingMutation = loadingReducer("mutation", true);
const stopLoadingMutation = loadingReducer("mutation", false);

export const tpsSlice = createSlice({
  name: "TPS",
  initialState,
  reducers: {
    setAppliedSearchText: (state, action) => {
      state.filter.q = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboard.pending, startLoadingQuery)
      .addCase(dashboard.fulfilled, (state, action) => {
        state.count = action.payload;
        state.loading.query = false;
      })
      .addCase(dashboard.rejected, stopLoadingQuery);
    builder
      .addCase(fetchAllTPS.pending, startLoadingQuery)
      .addCase(fetchAllTPS.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading.query = false;
      })
      .addCase(fetchAllTPS.rejected, stopLoadingQuery);
    builder
      .addCase(fetchAllByTanggalFilter.pending, startLoadingQuery)
      .addCase(fetchAllByTanggalFilter.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading.query = false;
      })
      .addCase(fetchAllByTanggalFilter.rejected, stopLoadingQuery);
    builder
      .addCase(fetchOneTPS.pending, startLoadingQuery)
      .addCase(fetchOneTPS.rejected, stopLoadingQuery)
      .addCase(fetchOneTPS.fulfilled, (state, action) => {
        state.loading.query = false;
        state.selected = action.payload;
      });
    builder
      .addCase(updateTPS.pending, startLoadingQuery)
      .addCase(updateTPS.rejected, stopLoadingQuery)
      .addCase(updateTPS.fulfilled, (state, action) => {
        state.loading.query = false;
        state.selected = action.payload;
      });
    builder
      .addCase(fetchTPSByJenis.pending, startLoadingQuery)
      .addCase(fetchTPSByJenis.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading.query = false;
      })
      .addCase(fetchTPSByJenis.rejected, stopLoadingQuery);
    builder
      .addCase(deleteTPS.pending, startLoadingMutation)
      .addCase(deleteTPS.fulfilled, stopLoadingMutation)
      .addCase(deleteTPS.rejected, stopLoadingMutation);
  },
});

export const { setSelectedRows, setAppliedSearchText } = tpsSlice.actions;

export default tpsSlice.reducer;
